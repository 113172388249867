/**
 * Wait for a group of webfonts to be loaded and added to the document's head tag.
 * @param {object[]} fonts - Array of font objects
 * @param {AbortController} controller - Controller to reject the promise if aborted
 * @returns {boolean} - Whether the loading was successful
 */
export const loadFonts = async (fonts = [], controller) => {
  if (!fonts.length || !document.fonts) {
    return false;
  }

  const style = document.createElement('style');
  document.head.appendChild(style);

  try {
    await Promise.all(
      fonts.map(async (font) => {
        if (controller?.signal) {
          controller.signal.addEventListener('abort', () => Promise.reject());
        }

        try {
          const fontFace = new FontFace(font.name, `url(${font.url})`);
          const fontData = await fontFace.load();
          document.fonts.add(fontData);
          return fontData;
        } catch (error) {
          return false;
        }
      })
    );

    await document.fonts.ready;
    return true;
  } catch (error) {
    return false;
  }
};
